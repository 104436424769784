@import "variables";

html,
body {
  height: 100%;
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-animation {
  animation-delay: 2s;
  animation: fade-in 2s;
  animation-fill-mode: forwards;
}

body {
  background-color: $color-background;
  color: $color-text;
  font-family: "Franklin Gothic Medium", sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: opacity 2s;

  &.loading {
    opacity: 0;
  }
}

.swiper-container {
  flex-grow: 1;
  width: 100%;
}

.slide-up-container {
  flex-grow: 1;
  overflow: hidden;

  > * {
    height: 100%;
  }
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.animate {
  transition: all 0.5s linear 0s;
}

.hide {
  display: none !important;
}

.invisible {
  opacity: 0;
}

@import "range";
@import "form";
@import "header";
@import "title";

@import "arrivals";
@import "alerts";
@import "login";
@import "closed";
@import "stops";
@import "edit-stop";
@import "spinner";
@import "audio";
@import "connection-lost";
@import "broadcasts";
