.screen-title {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 0 2.5rem;
  margin: 0 2rem 2rem 2rem;
  height: 7.5rem;

  &__text {
    margin: 0 0 0 2rem;
    font-size: 4.5rem;
  }

  &--arrivals {
    background-color: $color-arrivals-header;
  }

  &--alerts {
    background-color: #d61328;
  }
}
