.form {
  border-radius: 10px;
  background-color: rgba(white, 0.85);
  border-top: 12px solid $color-login-border-top;
  box-shadow: 5px 5px 20px rgba(#000, 0.15);
  color: $color-text-dark;
  padding: 2rem 2.5rem 3rem 2.5rem;

  &__title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }

  &__field {
    margin-bottom: 2rem;
  }

  &__label {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__image-preview {
    height: 250px;
    width: 100%;
    background-image: url("/images/default-stop-photo.png");
    border-radius: 10px;
    position: relative;
    background-size: cover !important;

    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .image-upload-overlay {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      border: 4px dashed rgba(0,0,0,0.1);
      border-radius: 10px;
      background-color: rgba(#cdd5d4, 0.8);
      display: none;

      img {
        width: 30%;
      }
    }

    &:hover {
      .image-upload-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__input {
    display: inline-flex;
    align-items: center;
    width: 32rem;
    height: 4.5rem;
    border: 2px solid $color-login-input-border;
    border-radius: 10px;
    font-size: 2rem;
    padding: 0 1.5rem;
    transition: border-color 0.25s;

    &:hover {
      border-color: $color-login-input-border-hover;
    }

    &:focus {
      border-color: $color-login-input-border-focus;
    }
  }

  &__button {
    display: block;
    width: 100%;
    height: 4.5rem;
    background-color: $color-login-button-background;
    color: $color-text-light;
    border-radius: 1000rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    margin-top: 3rem;
    transition: background-color 0.25s;
    outline: none;

    &:hover {
      background-color: darken($color-login-button-background, 1.5);
    }

    &:active {
      background-color: darken($color-login-button-background, 4);
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
