.arrival-card-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 2rem !important;
}

.arrival-card {
  display: flex;
  align-items: center !important;
  transition: background 400ms !important;
  border-radius: 12px;
  background: var(--line-color);

  &__content {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .left {
      // padding-left: 3rem;
    }
    .right {
      // padding-right: 3rem;
    }
  }

  &--hide {
    display: none;
  }

  &--now {
    background-color: transparent !important;
    animation-name: arrival-now;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  &--last &__last {
    display: inline-flex;
    animation-name: arrival-last;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__last {
    display: none;
    align-items: center;
    justify-content: center;
    border: 5px solid $color-arrival-last;
    border-radius: 12px;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    height: 3.5rem;
    width: 100%;
    margin-top: 1rem;
  }

  &__info {
    display: flex;
    align-items: center;
    font-size: 4.5rem;
  }

  &__logo {
    margin-left: 0.75rem;
    margin-right: 1rem;
  }

  &__location {
    // font-size: 10rem;
    line-height: 1;
    max-width: 1000px;
    width: 100%;
    span {
      font-size: 10rem;
    }
  }

  &__time, &__route_short, &__location {
    font-weight: bold;
  }

  &__time {
    // font-size: 13rem;
    line-height: 0.8;

    small {
      // font-size: 8rem;
      font-weight: normal;
    }
  }

  &--now &__time {
    text-transform: uppercase;
    font-size: 12.5rem;
  }
}

.superstop {
  .arrival-card {
    &__content {
      font-weight: normal;
      .left {
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: .25;

        .upper {
          flex: .4;
          line-height: .8;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
        }

        .bottom {
          flex: .5;
          line-height: .8;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
        }

        .max-height {
          height: 10vh;
        }
      }
      .center {
        flex: .5;
        justify-content: center;
        display: flex;
        padding-top: 1.5em;
      }
      .right {
        justify-content: center;
        flex: .25;
        display: flex;
      }
    }
    &__location {
      justify-content: center;
      display: flex;
    }

    &__bus-wrapper { // "Bus 1905"
      font-size: 8vh;
      line-height: 0.9;
    }
    &__route_short  { // "10"
      font-size: 16vh;
      line-height: 0.8;
    }
    &__time { // "NOW"
      font-size: 16vh;
    }

    @media (max-height: 750px) {
      &__bus-wrapper  { font-size: 7vh; }
      &__route_short { font-size: 13vh; }
    }

    @media (max-height: 700px) {
      &__bus-wrapper { font-size: 6vh; }
      &__route_short { font-size: 11vh; }
    }

    @media (max-height: 600px) {
      &__bus-wrapper { font-size: 6vh; }
      &__route_short { font-size: 11vh; }
    }

    // "10"
    &__route_short, &__time {
      small {
        font-weight: normal;
      }
    }

  }

}

.alert-notice {
  display: flex;
  height: 7rem;
  margin: 2rem 2rem 0 2rem;

  &__title {
    display: inline-flex;
    align-items: center;
    background-color: $color-alert-notice-border;
    color: $color-text-dark;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    font-size: 3.5rem;
    font-weight: bold;
    padding: 0 2rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &__text-container {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    border: 4px solid $color-alert-notice-border;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: $color-alert-notice-background;
    font-size: 3rem;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    span {
      padding: 0 2rem;
    }
  }
}

.arrival-notice {
  display: inline-flex;
  align-items: center;
  font-size: 21px;
  font-weight: bold;
  margin: 1rem 2.5rem;
}

@keyframes arrival-now {
  from {
    color: $color-text;
  }
  to {
    color: $color-arrival-now;
  }
}

@keyframes arrival-last {
  from {
    color: var(--line-color);
    background-color: $color-arrival-last;
  }
  to {
    color: $color-arrival-last;
    background-color: transparent;
  }
}
