.screen-header {
  display: flex;
  align-items: center;
  margin: 2rem 3.5rem;
  height: 4rem;
  z-index: 10;

  a {
    display: inline-flex;
    font-size: 2.5rem;
    color: white;
    font-weight: bold;
    margin-right: 2rem;
  }

  &__spacer {
    flex: 1;
  }

  &__datetime {
    font-size: 3rem;
    font-weight: bold;
  }
}
