.stops-page {
  background-image: url("/images/login-background.png");
  background-size: cover;
  background-position: center;
}

.stops-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stops-list {
  border-radius: 10px;
  background-color: rgba(white, 0.85);
  border-top: 12px solid $color-login-border-top;
  box-shadow: 5px 5px 20px rgba(#000, 0.15);
  color: $color-text-dark;
  padding: 2rem 2.5rem 3rem 2.5rem;

  &__list {
    max-width: 900px;
    max-height: 580px;
    overflow-y: auto;
    margin: -10px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }

  &__card {
    width: calc(50% - 20px);
    float: left;
    margin: 10px;
  }

  &__background {
    background-image: url("/images/default-stop-photo.png");
    height: 225px;
    background-size: cover !important;
    background-position: 50% !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item a {
    display: block;
    padding: 0.75rem;
    color: inherit;
    text-decoration: none;
    font-size: 1.2rem;
    background: rgba(0, 0, 0, 0.1);

    &:first-of-type {
      width: 100%;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      width: 60px;
      text-align: center;
      position: relative;
      border-left: 1px solid $color-gray-light;
      border-bottom-right-radius: 10px;
    }

    &:hover {
      background: rgba(#000, 0.2);
    }
  }
}
