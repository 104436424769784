$color-black: black;
$color-gray: #414141;
$color-gray-dark: #2c2c2c;
$color-gray-light: #cccccc;
$color-white: white;
$color-yellow: #fff684;
$color-orange: #ffc709;
$color-green: #47ad48;
$color-blue: #00539f;

$color-background: $color-black;
$color-text-light: $color-white;
$color-text-dark: $color-black;
$color-text: $color-text-light;
$color-arrivals-header: $color-gray;
$color-arrival-now: $color-yellow;
$color-arrival-last: $color-yellow;
$color-alert-notice-border: $color-orange;
$color-alert-notice-background: $color-gray;

$color-alert-card-background: $color-gray;
$color-alert-card-header: $color-gray-dark;

$color-login-border-top: $color-green;
$color-login-input-border: $color-gray-light;
$color-login-input-border-hover: $color-blue;
$color-login-input-border-focus: $color-blue;
$color-login-button-background: $color-blue;

$color-closed-title-background: $color-green;
$color-closed-link: $color-green;
