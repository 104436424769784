.login-page {
  background-image: url("/images/login-background.png");
  background-size: cover;
  background-position: center;
}

.login-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
