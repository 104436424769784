// http://danielstern.ca/range.css/#/

@mixin thumb-style {
  box-shadow: 1.2px 1.2px 0px rgba(0, 0, 0, 0.43),
    0px 0px 1.2px rgba(13, 13, 13, 0.43);
  height: 40px;
  width: 40px;
  border-radius: 36px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15px;
}

@mixin track-style {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: rgba(0,0,0,0.1);
  border-radius: 10px;
  border: 0.2px solid rgba(0,0,0,0.15);
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 9.8px 0;
  background: transparent;

  &::-webkit-slider-thumb {
    @include thumb-style();
  }
  &::-moz-range-thumb {
    @include thumb-style();
  }
  &::-moz-range-track {
    @include track-style();
  }
  &::-webkit-slider-runnable-track {
    @include track-style();
  }

  &:focus {
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}
