.connection-lost-screen {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    flex-grow: 1;
  
    &__title {
      padding: 4.5rem 0;
      font-size: 7.5rem;
      font-weight: bold;
      background: $color-closed-title-background;
      text-align: center;
    }
  
    &__text {
      text-align: center;
      font-size: 5rem;
    }
  
    a {
      color: $color-closed-link;
      text-decoration: none;
    }
  }
  