.edit-stop-page {
  background-image: url(/images/login-background.png);
  background-size: cover;
  background-position: center;

  .edit-stop-container {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .volume-control-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    #speaker-volume-label {
      font-weight: 600;
      font-size: 3em;
      margin-bottom: 10px;
    }
  }

  .edit-stop-grid {
    display: flex;
    width: 800px;

    .left-col {
      flex: 1;
      margin-right: 60px;
    }

    .full-col {
      width: 100%;
      #broadcast-textarea {
        border: 1px solid grey;
        border-radius: 6px;
      }
      .broadcast-label {
        display: block;
        width: 100%;
      }
      .form__button.pull-right {
        width: 20%;
        float: right;
        margin-top: 0rem;
        margin-bottom: 2rem;
        #broadcast-button {
          text-align: center;
          width: 100%;
          height: 100%;
          background: transparent;
          color: white;
          border: none;
        }
      }
    }

    .right-col {
      flex: 1;
    }
  }
}
