.broadcast-alert-wrapper {
  background-color: #222;
  color: #fff;
}


.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 199, 9, 1);
  z-index: 9;
  .stop-broadcast-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-wrap: wrap;
    color: #000;
    top: calc(50% - 5em);
    margin: auto;
    text-align: center;
    width: calc(100% - 2em);
  }
}
.broadcast-page {
  background-image: url(/images/login-background.png);
  background-size: cover;
  background-position: center;
  #broadcast-station-error {
    text-align:center;
    width:100%;
    display:block;
    color:red;
  }
  #broadcast-message-error {
    width:100%;
    display:block;
    color:red;
  }
  > * {
    color: #222;
  }

  .broadcast-message-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(90% - 1.1em);
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    .form {
      max-height: 600px;

      textarea {
        width: 100%;
        background: transparent;
        resize:none;
        color: #222;
        border: 1px solid #222;
        border-radius: 10px;
        font-size: 16pt;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      }
      .text-center {
        text-align: center;
      }
      .broadcast-btn-group {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        > button {
          width: 200px;
          margin-left: 1.5rem;
        }
      }
      .section-offset.in {
        width: calc(90% - 1.1em);
        margin: auto;
      }
      div#broadcast-confirm-post-dialog.in {
        .confirm-header,
        .confirm-body {
          padding: 9px 14px;
          font-size: 16pt;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        }
        .confirm-footer {
          padding: 9px 14px;
          font-size: 16pt;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          .form__button {
            width: 200px;
            margin-left: 1.5rem;
          }
        }
        .confirm-header > .close {
          color: #7b7b7b;
          background: transparent;
          display: flex;
          padding: 1rem;
          margin: -1rem -1rem -1rem auto;
          border: 1px solid #7b7b7b;
          font-size: 22pt;
        }
        .confirm-body {
          margin-bottom: 1rem;
        }
      }
      .form__field {
        &.checkbox {
          display: grid;
          display: -ms-grid;
          -ms-grid-columns: 45% 50%;
          grid-template-columns: 45% 45%;
          font-size: 16pt;
          grid-column-gap: 5%;
          .form-check.custom-checkbox.custom-control {
            position: relative;
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5rem;
            width: 55%;
            margin-left: auto;
            margin-right: auto;
            &:nth-of-type(2n + 1) {
              position: relative;
              grid-column: 1;
              -ms-grid-column: 1;
              padding-left: 1.25rem;
            }
            &:nth-of-type(2n + 2) {
              position: relative;
              grid-column: 2;
              -ms-grid-column: 2;
              padding-left: 1.25rem;
            }
            .custom-control-input {
              position: absolute;
              z-index: -1;
              opacity: 0;
            }
            .custom-control-label {
              position: relative;
              margin-bottom: 0;
              vertical-align: top;
              &::before {
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                pointer-events: none;
                content: "";
                background-color: #fff;
                border: #222 solid 1px;
              }
              &::after {
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                content: "";
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
              }
            }
            .custom-control-input:checked + .custom-control-label::after {
              position: absolute;
              top: 0.25rem;
              left: -23px;
              display: block;
              width: 1.1rem;
              height: 1.1rem;
              content: "";
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              background-image: url(/images/checkbox.png);
            }
          }

          .form__button {
            width: 200px;
            display: flex;
            justify-content: center;
            margin-left: 15px;
            margin-top: 5px;
          }
          .form-check-input {
            position: absolute;
            margin-top: 0.3rem;
            margin-left: -1.25rem;
          }
          .form-check-label {
            display: inline-block;
            margin-bottom: 0;
          }

          label.broadcast-label {
            font-weight: bold;
            font-size: 16px;
            &::before {
              position: absolute;
              top: 0.25rem;
              left: 0;
              display: block;
              width: 1rem;
              height: 1rem;
              content: "";
              background-color: transparent;
              border: 1px solid #222;
            }
          }
        }
      }
    }
  }
}
#step-broadcast-stations:not(.in),
#step-broadcast-msg:not(.in),
#broadcast-confirm-post-dialog:not(.in) {
  display: none;
}
