.alert-card-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 2rem 2rem 2rem;
}

.alert-card {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  border-radius: 12px;
  background-color: $color-alert-card-background;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__left {
    width: 14rem;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--line-color);
  }

  &__right {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__logo {
    min-width: 7rem;
    max-width: 10.5rem;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $color-alert-card-header;
    padding: 0 1rem;
    height: 3rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &__body {
    flex-grow: 1;
    padding: 1rem;
  }

  &__line {
    margin: 0;
    font-size: 2.5rem;
  }

  &__text {
    margin: 0;
    font-size: 2.45rem;
  }
}
