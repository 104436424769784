.audio-page {
  background-image: url("/images/login-background.png");
  background-size: cover;
  background-position: center;
}

.audio-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-list {
  border-radius: 10px;
  background-color: rgba(white, 0.85);
  border-top: 12px solid $color-login-border-top;
  box-shadow: 5px 5px 20px rgba(#000, 0.15);
  color: $color-text-dark;
  padding: 2rem 2.5rem 3rem 2.5rem;

  &__title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background: rgba(#000, 0.1);
    overflow: auto;
    border-radius: 5px;

    max-height: 500px;
    overflow-y: scroll;
  }

  &__item:not(:first-child) {
    border-top: 1px solid rgba(#000, 0.1);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 32rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.25s all;

    img {
      flex-shrink: 0;
      margin-left: 1rem;
      opacity: 0;
      transition: 0.25s all;
    }

    &:hover {
      background: rgba(#000, 0.1);
    }

    &:active {
      background: rgba(#000, 0.2);
    }

    &.active {
      font-weight: bold;
      background: rgba(#000, 0.2);

      img {
        opacity: 1;
      }
    }
  }
}
